import { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { intlErrorHandler, intlGetProviderLocale } from 'src/utils/intl';

export type LocaleOverrideProps = {
  locale?: string;
  messages?: Record<string, string>;
};

export const LocaleOverride: FC<PropsWithChildren<LocaleOverrideProps>> = ({
  children,
  locale,
  messages,
}) => {
  if (!locale) {
    return children;
  }
  return (
    // key ensures it updates when locale changes
    <IntlProvider
      key={locale}
      locale={intlGetProviderLocale(locale)}
      messages={messages}
      onError={intlErrorHandler}
    >
      {children}
    </IntlProvider>
  );
};
