import { AuthApi } from 'src/api';
import { getTimezone } from 'src/utils/dateTime';
import { LanguagesInformation } from './analytics/getAnalyticsLanguageInfo';

export interface GoogleResponse {
  clientId?: string;
  credential: string;
  select_by: string;
}

export const authenticateWithMobileToken = async (
  accessToken: string,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  await AuthApi.web({
    token: accessToken,
    acquisition_channel,
  });
};

export const authenticateWithGoogle = async (
  authResponse: GoogleResponse,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
  languagesInformation?: LanguagesInformation,
) => {
  const { credential: id_token } = authResponse;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.google({
    id_token,
    timezone,
  });

  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  }).then(() => {
    if (user.is_new && user.id) {
      window.dataLayer.push({
        event: 'sign_up',
        method: 'google',
        logged_in: 'true',
        user_id: `${user.id}`,
        page_screen_type: 'Registration',
        page_screen_name: 'Registration completed',
        ...languagesInformation,
      });
    }
  });

  return { user };
};

export interface AuthResponseType {
  accessToken: string;
}

export const authenticateWithFacebook = async (
  authResponse: AuthResponseType,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
  languagesInformation?: LanguagesInformation,
) => {
  const fb_access_token = authResponse.accessToken;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.facebook({
    fb_access_token,
    timezone,
  });

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  }).then(() => {
    if (user.is_new && user.id) {
      window.dataLayer.push({
        event: 'sign_up',
        method: 'facebook',
        logged_in: 'true',
        user_id: `${user.id}`,
        page_screen_type: 'Registration',
        page_screen_name: 'Registration completed',
        ...languagesInformation,
      });
    }
  });

  return { user };
};

export interface AuthorizationTypeApple {
  id_token: string;
}

export const authenticateWithApple = async (
  authResponse: AuthorizationTypeApple,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
  languagesInformation?: LanguagesInformation,
) => {
  const id_token = authResponse.id_token;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.apple({
    id_token,
    timezone,
  });

  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  }).then(() => {
    if (user.is_new && user.id) {
      window.dataLayer.push({
        event: 'sign_up',
        method: 'apple',
        logged_in: 'true',
        user_id: `${user.id}`,
        page_screen_type: 'Registration',
        page_screen_name: 'Registration completed',
        ...languagesInformation,
      });
    }
  });

  return { user };
};

export const signUpWithEmail = async (
  signUpDetails: {
    email: string;
    password: string;
    username?: string;
  },
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
  languagesInformation?: LanguagesInformation,
) => {
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.signUp({
    ...signUpDetails,
    timezone,
  });

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  }).then(() => {
    /* istanbul ignore else: empty else branch */
    if (user.id) {
      /* istanbul ignore else: empty else branch */
      window.dataLayer.push({
        event: 'sign_up',
        method: 'email',
        logged_in: 'true',
        user_id: `${user.id}`,
        page_screen_type: 'Registration',
        page_screen_name: 'Registration completed',
        ...languagesInformation,
      });
    }
  });

  return { user };
};

export const signInWithEmail = async (signInDetails: { username: string; password: string }) => {
  const { access_token, user } = await AuthApi.accessToken(signInDetails);

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
  });

  return { user };
};
