export const scrollToTop = () => {
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
};

export const blurFromAllElements = () => {
  /* istanbul ignore next */
  if (typeof document === 'undefined' || !document.activeElement) {
    return;
  }

  const activeElement = document.activeElement as HTMLElement;

  if (activeElement.blur && document.activeElement !== document.body) {
    activeElement.blur();
  }
};
