import React, { FormEvent, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MemriseApiError, ScenariosBetaApi } from 'src/api';
import {
  authenticateWithApple,
  authenticateWithFacebook,
  authenticateWithGoogle,
  GoogleResponse,
  signInWithEmail,
} from 'src/utils/auth';
import { makeUrlRelativeOrDashboard } from 'src/utils/location';
import { Header, Input, Label, Page } from 'src/components';
import { AppleAuthButton, FacebookAuthButton, GoogleAuthButton } from 'src/utilityComponents';
import RedirectIfAuth from 'src/utilityComponents/RedirectIfAuth';
import { stringifyUrl } from 'src/utils/stringifyUrl';
import { blurFromAllElements } from 'src/utils/browser';
import { ROUTES } from 'src/constants/routes.const';
import { useUserContext } from 'src/context/user';
import useSafePush from 'src/hooks/useSafePush';
import { ClassicButton } from 'src/componentsClassic';
import { cleanupWebStoragesOnLogout } from 'src/utils/userWebStorages';
import * as S from './styles';

interface Inputs {
  username: string;
  password: string;
  // needed so we can set errors associated with Google/Facebook
  google?: string;
  facebook?: string;
  apple?: string;
  usernameSubmit?: string;
}

const msgs = defineMessages({
  oopsSomethingWentWrong: {
    id: 'something_went_wrong',
  },
  title: {
    id: 'login_page_new_header',
  },
  password: {
    id: 'Password',
  },
  requiredPassword: {
    id: 'login_page_error_no_password',
  },
  emailPlaceholder: {
    id: 'onboarding_signup_email_placeholder',
  },
  forgotPassword: {
    id: 'login_page_forgot_password',
  },
  noAccount: {
    id: 'login_page_no_account',
  },
  or: {
    id: 'login_methods',
  },
  login: {
    id: 'sign_in',
  },
  facebookLogin: {
    id: 'sign_in_facebook',
  },
  appleLogin: {
    id: 'sign_in_apple',
  },
  wrongEmailOrPassword: {
    id: 'login_page_wrong_email_or_password',
  },
  usernameOrEmail: {
    id: 'username_or_email',
  },
  absentUsernameOrEmail: {
    id: 'login_page_username_error',
  },
  loginLoading: {
    id: 'login_page_loading',
  },
  cookiesError: {
    id: 'login_page_error_cookies',
  },
  pageHeader: {
    id: 'signin_page_head',
    defaultMessage: 'Log in to your account',
  },
});

const Signin = () => {
  const [isAuthorising, setIsAuthorising] = useState(false);
  const intl = useIntl();
  const router = useRouter();
  const { safePush } = useSafePush();
  const userContext = useUserContext();
  const { next } = router.query;

  const schema = yup.object().shape({
    username: yup.string().required(intl.formatMessage(msgs.absentUsernameOrEmail)),
    password: yup.string().required(intl.formatMessage(msgs.requiredPassword)),
    google: yup.string(),
    facebook: yup.string(),
    apple: yup.string(),
    usernameSubmit: yup.string(),
  });

  const { formState, register, handleSubmit, setError, clearErrors } = useForm<Inputs>({
    mode: 'all',
    resolver: yupResolver<Inputs>(schema),
  });
  const { isValid, errors } = formState;

  const handleRedirect = async () => {
    await safePush(makeUrlRelativeOrDashboard(next?.toString() || ROUTES.dashboard.scenarios));
    userContext.refetchUser();
  };

  const handleGoogleSignIn = async (googleResponse: GoogleResponse) => {
    setIsAuthorising(true);

    try {
      const { user } = await authenticateWithGoogle(googleResponse);

      if (user.is_new) {
        await ScenariosBetaApi.forceJoinScenariosBeta();
      }

      await handleRedirect();
    } catch (err) {
      setIsAuthorising(false);
      setError('usernameSubmit', {
        message: intl.formatMessage(msgs.oopsSomethingWentWrong),
      });
    }
  };

  const handleFacebookSignIn = () => {
    setIsAuthorising(true);
    // Mocking this window script is tough
    /* istanbul ignore next */
    // https://developers.facebook.com/docs/reference/javascript/FB.login/v5.0
    if (window.FB?.login) {
      window.FB.login(async res => {
        try {
          const isError = res.authResponse === null;
          if (isError) {
            setError('usernameSubmit', {
              message: intl.formatMessage(msgs.oopsSomethingWentWrong),
            });
            setIsAuthorising(false);
          } else {
            const { user } = await authenticateWithFacebook(res.authResponse);

            if (user.is_new) {
              await ScenariosBetaApi.forceJoinScenariosBeta();
            }

            await handleRedirect();
          }
        } catch (err) {
          setError('usernameSubmit', {
            message: intl.formatMessage(msgs.oopsSomethingWentWrong),
          });

          setIsAuthorising(false);
        }
      });
    } else {
      setError('facebook', {
        message: intl.formatMessage(msgs.cookiesError),
      });

      setIsAuthorising(false);
    }
  };

  // Should be tested in the future
  /* istanbul ignore next */
  const handleAppleSignIn = async () => {
    setIsAuthorising(true);
    if (window.AppleID?.auth) {
      try {
        const data = await window.AppleID.auth.signIn();

        try {
          const isError = data.authorization === null;
          if (isError) {
            setError('usernameSubmit', {
              message: intl.formatMessage(msgs.oopsSomethingWentWrong),
            });
            setIsAuthorising(false);
          } else {
            const { user } = await authenticateWithApple(data.authorization);

            if (user.is_new) {
              await ScenariosBetaApi.forceJoinScenariosBeta();
            }

            await handleRedirect();
          }
        } catch (err) {
          setError('usernameSubmit', {
            message: intl.formatMessage(msgs.oopsSomethingWentWrong),
          });

          setIsAuthorising(false);
        }
      } catch (error) {
        setError('usernameSubmit', {
          message: intl.formatMessage(msgs.oopsSomethingWentWrong),
        });
        setIsAuthorising(false);
      }
    } else {
      setError('apple', {
        message: intl.formatMessage(msgs.cookiesError),
      });

      setIsAuthorising(false);
    }
  };

  const handleEmailSignIn = async (signInDetails: { username: string; password: string }) => {
    setIsAuthorising(true);

    try {
      const { user } = await signInWithEmail(signInDetails);

      if (user.is_new) {
        await ScenariosBetaApi.forceJoinScenariosBeta();
      }

      await handleRedirect();
    } catch (err) {
      setIsAuthorising(false);
      if (err instanceof MemriseApiError && err.httpStatusCode === 400) {
        // Unfortunately the access_token endpoint doesn't return a code value,
        // so we can't tell what went wrong. Show generic message - likely wrong
        // email or password.
        setError('usernameSubmit', {
          message: intl.formatMessage(msgs.wrongEmailOrPassword),
        });
      } else {
        // Show more generic error for 500 errors etc.
        setError('usernameSubmit', {
          message: intl.formatMessage(msgs.oopsSomethingWentWrong),
        });
      }
    }
  };

  const username = register('username');
  const password = register('password');

  useEffect(() => {
    if (isAuthorising) {
      // Clear any remaining data from the previous user
      cleanupWebStoragesOnLogout();
    }
  }, [isAuthorising]);

  if (userContext.loading) {
    return (
      <S.LoaderWrapper>
        <S.Loader />
      </S.LoaderWrapper>
    );
  }

  return (
    <Page
      header={
        !userContext.user ? (
          <Header linkConfig="none" logoUrl="https://www.memrise.com/" />
        ) : undefined
      }
    >
      <S.Root>
        <Head>
          <title>{intl.formatMessage(msgs.pageHeader)} - Memrise</title>
        </Head>
        <RedirectIfAuth slug={next?.toString()}>
          <>
            <S.Title>{intl.formatMessage(msgs.title)}</S.Title>
            <S.SocialButtonsWrapper>
              <GoogleAuthButton
                onSignUp={handleGoogleSignIn}
                error={errors.google?.message}
                disabled={isAuthorising}
              />
              <FacebookAuthButton
                disabled={isAuthorising}
                error={errors.facebook?.message}
                onSignUp={handleFacebookSignIn}
              >
                {intl.formatMessage(msgs.facebookLogin)}
              </FacebookAuthButton>
              <AppleAuthButton
                disabled={isAuthorising}
                error={errors.apple?.message}
                onSignUp={handleAppleSignIn}
              >
                {intl.formatMessage(msgs.appleLogin)}
              </AppleAuthButton>
            </S.SocialButtonsWrapper>

            <p>{intl.formatMessage(msgs.or)}</p>
            <S.Form
              onSubmit={async (event: FormEvent) => {
                blurFromAllElements();
                event.preventDefault();
                await handleSubmit(handleEmailSignIn)(event);
              }}
              method="post"
            >
              <div>
                <Label htmlFor="username">{intl.formatMessage(msgs.usernameOrEmail)}</Label>
                <Input
                  id="username"
                  data-testid="signinUsernameInput"
                  placeholder={intl.formatMessage(msgs.emailPlaceholder)}
                  error={errors.username?.message}
                  {...username}
                  onChange={async (e: FormEvent) => {
                    await username.onChange(e);
                    clearErrors();
                  }}
                  large
                  disabled={isAuthorising}
                />
              </div>

              <S.InputGroup>
                <Label htmlFor="password">{intl.formatMessage(msgs.password)}:</Label>
                <Input
                  id="password"
                  data-testid="signinPasswordInput"
                  type="password"
                  error={errors.password?.message}
                  {...password}
                  onChange={async (e: FormEvent) => {
                    await password.onChange(e);
                    clearErrors();
                  }}
                  large
                  placeholder="••••••••"
                  disabled={isAuthorising}
                />
                <S.Links href="/password/reset/" $alignSelf="flex-end">
                  {intl.formatMessage(msgs.forgotPassword)}
                </S.Links>
              </S.InputGroup>

              <ClassicButton
                disabled={!isValid}
                type="submit"
                data-testid="signinFormSubmit"
                error={errors.usernameSubmit?.message}
                loading={isAuthorising}
                loadingContent={intl.formatMessage(msgs.loginLoading)}
              >
                {intl.formatMessage(msgs.login)}
              </ClassicButton>

              <Link
                href={stringifyUrl(
                  ROUTES.bienvenue,
                  router.query as { [index: string]: string | number },
                )}
                passHref
                legacyBehavior
              >
                <S.SignupLink $hasError={!!errors.usernameSubmit?.message}>
                  {intl.formatMessage(msgs.noAccount)}
                </S.SignupLink>
              </Link>
            </S.Form>
          </>
        </RedirectIfAuth>
      </S.Root>
    </Page>
  );
};

export default Signin;
