import { NextPage } from 'next';
import SignIn from 'src/apps/Signin';
import {
  getSharedServerSideProps,
  SharedServerSideProps,
} from 'src/utils/getSharedServerSideProps';
import { LocaleOverride } from 'src/components/LocaleOverride';

export const getServerSideProps = getSharedServerSideProps;

const Page: NextPage<SharedServerSideProps> = ({ locale, messages, ...rest }) => {
  return (
    <LocaleOverride locale={locale} messages={messages}>
      <SignIn {...rest} />
    </LocaleOverride>
  );
};

export default Page;
